import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import Overlay from "../components/overlay"
import PreviewCompatibleImage from "../components/previewCompatibleImage"
import { FaSearchPlus } from "react-icons/fa"

const RoomTemplateWrapper = styled.section`
  margin: 4rem 0;
  padding: 1rem 0;

  .room {
    display: grid;
    grid-gap: 40px;
    grid-template-areas: "image description";
    grid-template-columns: auto 30%;

    .room-header__title {
      text-transform: uppercase;
    }

    .room-header__area,
    .room-header__capacity {
      display: flex;
      font-size: 1.2rem;
      span {
        width: 70%;
      }
      span:last-child {
        width: 30%;
      }
    }
    @media (max-width: 860px) {
      grid-template-areas: "image" "description";
      grid-template-columns: 1fr;
    }
  }

  .room-header__area span:nth-child(2),
  .room-header__capacity span:nth-child(2) {
    font-weight: bold;
  }

  .description {
    grid-area: description;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    p {
      text-align: justify;
      text-justify: inter-word;
    }
    a {
      align-self: flex-start;
    }
  }

  .room-images {
    grid-area: image;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    figure {
      margin: 0;
      max-height: 260px;
      overflow: hidden;
      position: relative;
      cursor: zoom-in;
      border-radius: 5px;

      .room-image__overlay {
        background: rgba(238, 120, 121, 0.5);
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s;
        transform: translateY(120%);
        font-size: 2rem;
        color: white;
      }
      :hover .room-image__overlay {
        transform: translateY(0);
      }
    }
  }
`

class RoomTemplate extends Component {
  state = {
    src: null,
    overlayOpen: 0,
  }
  handleImageClick = fluid => {
    this.setState({
      src: fluid,
      overlayOpen: 1,
    })
  }

  backdropClickHandler = () => {
    this.setState({ overlayOpen: 0 })
  }

  render() {
    const { title, area, capacity, description, images } = this.props

    return (
      <RoomTemplateWrapper>
        <article>
          <div className="room">
            <div className="description">
              <header>
                <div className="room-header__title">
                  <h2>{title}</h2>
                </div>
                <div className="room-header__area">
                  <span>Powierzchnia:</span>
                  <span>{area} m2</span>
                </div>
                <div className="room-header__capacity">
                  <span>Ilość gości:</span>
                  <span>{capacity} os.</span>
                </div>
              </header>
              <div className="room-header__description">
                <p>{description}</p>
              </div>
              <Link className="to-item-link" to="/kontakt/">
                Zarezerwuj pokój
              </Link>
            </div>
            <div className="room-images">
              {images.map(item => {
                if (!!item.image && !!item.image.childImageSharp) {
                  return (
                    <figure
                      key={item.image.id}
                      onClick={this.handleImageClick.bind(
                        this,
                        item.image.childImageSharp.fluid
                      )}
                    >
                      <PreviewCompatibleImage
                        imageInfo={item}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                        }}
                      />
                      <div className="room-image__overlay">
                        <FaSearchPlus />
                      </div>
                    </figure>
                  )
                }
                if (!!item.image && typeof item.image === "string")
                  return (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                        }}
                        src={item.image}
                        alt={item.imageAlt}
                      />
                    </div>
                  )
                return null
              })}
            </div>
          </div>
        </article>
        <Overlay
          show={this.state.overlayOpen}
          click={this.backdropClickHandler}
          src={this.state.src}
        />
      </RoomTemplateWrapper>
    )
  }
}

RoomTemplate.propTypes = {
  title: PropTypes.string,
  area: PropTypes.number,
  capacity: PropTypes.number,
  desctiption: PropTypes.string,
  images: PropTypes.array,
}

export default RoomTemplate

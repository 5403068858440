import React from "react"
import PropTypes from "prop-types"
import Rooms from "../components/rooms"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const RoomsPage = ({ data }) => {
  const { edges: rooms } = data.roomData
  return (
    <Layout>
      <SEO
        title={"Pokoje"}
        description={
          "Do Państwa dyspozycji oddajemy 5 pokoi każdy wyposażony w łazienkę i aneks kuchenny, bezpłatne WiFi. Wokół domu jest dużo przestrzeni dla Waszych pociech, miejsce do grilowania, altana."
        }
        keywords={[
          "Pokoje gościnne",
          "Pokoje do wynajecia",
          "Pokoje w Bieszczadach",
          "Pokoje U Michałowej",
        ]}
      />
      <Rooms
        roomsItems={rooms}
        rooms={data.homePageData.edges[0].node.frontmatter.rooms}
      />
    </Layout>
  )
}

RoomsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default RoomsPage

export const pageQuery = graphql`
  query RoomsPageQuery {
    homePageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            rooms {
              title
              description
            }
          }
        }
      }
    }
    roomData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "room-template" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            templateKey
            area
            capacity
            description
            images {
              image {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
    }
  }
`

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"

import RoomTemplate from "../templates/room-template"

const RoomsWrapper = styled.section`
  .rooms-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .header {
    p {
      max-width: 70%;
    }
    @media (max-width: 860px) {
      p {
        max-width: 100%;
      }
    }
    ::after {
      content: "";
      width: 40px;
      height: 2px;
      background-color: rgba(238, 120, 121, 0.6);
      display: block;
    }
  }
  .rooms-btn {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
`

const Rooms = ({ rooms, roomsItems, id }) => (
  <RoomsWrapper>
    <div className="container rooms-container" id={id}>
      <article>
        <header>
          <div className="header">
            <h1>{rooms.title}</h1>
            <p>{rooms.description}</p>
          </div>
        </header>

        {roomsItems.map(room => (
          <RoomTemplate
            key={room.node.id}
            title={room.node.frontmatter.title}
            area={room.node.frontmatter.area}
            capacity={room.node.frontmatter.capacity}
            description={room.node.frontmatter.description}
            images={room.node.frontmatter.images}
          />
        ))}

        <div className="rooms-btn">
          {rooms.button ? (
            <Link className="to-group-link" to="/pokoje/">
              {rooms.button}
            </Link>
          ) : (
            <Link className="to-group-link" to="/">
              Strona Główna
            </Link>
          )}
        </div>
      </article>
    </div>
  </RoomsWrapper>
)

Rooms.propTypes = {
  rooms: PropTypes.object,
  roomsItems: PropTypes.array,
  id: PropTypes.string,
}
Rooms.defaultProps = {
  rooms: {},
  roomsItems: [],
  id: "rooms",
}

export default Rooms
